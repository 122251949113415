<script>
import DefaultEstoreProductSummary from '/~/extensions/estore/components/estore-summary.vue'
import ProductTotal from '/~/extensions/giftcards/templates/bill-payments/components/product/product-total.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'estore-product-summary',
  components: {
    ProductTotal,
    BaseIcon,
  },
  extends: DefaultEstoreProductSummary,
}
</script>

<template>
  <div class="sm:pb-20">
    <div class="text-center sm:text-left md:px-0">
      <h2
        v-if="retailer"
        class="text-2xl font-bold sm:text-3xl"
        data-cy="estore-retailer"
      >
        {{ retailer }}
      </h2>
      <h2
        class="text-2xl font-normal text-eonx-neutral-800 sm:text-3xl"
        data-test="header"
        data-cy="estore-name"
      >
        {{ name }}
      </h2>
      <div
        class="mb-6 mt-[5px] hidden space-x-[5px] text-eonx-neutral-600 sm:flex"
      >
        <base-icon svg="plain/truck" size="md" />
        <span>Delivered by supplier</span>
      </div>
      <product-header class="mt-5" :show-type="false" />
    </div>

    <div v-if="displayPoints" class="mt-5">
      My points balance
      <span class="text-lg font-bold">
        {{ pointsLeft }}
      </span>
    </div>
    <div class="mb-[30px] rounded border sm:border-0">
      <div
        class="mx-auto flex max-w-96 items-center justify-between bg-white px-5 py-[15px] text-xl sm:max-w-none sm:px-6"
      >
        <div data-cy="calculation-counter">
          <base-counter
            v-model="quantity"
            :min="1"
            :max="quantityMax"
            data-test="quantity"
            small
          />
        </div>
        <product-total :amount="total" />
      </div>
    </div>
    <div
      class="fixed bottom-0 left-0 z-popup mt-[30px] flex w-full items-center justify-end space-x-5 bg-white p-[15px] shadow-md sm:relative sm:z-auto sm:bg-transparent sm:p-0 sm:shadow-none"
      data-test="control"
    >
      <base-button
        v-if="isAddToCartEnabled"
        ref="addToCardButton"
        :disabled="
          adding || isAddButtonDisabled || isCheckingIdentityVerification
        "
        data-test="addToCard"
        class="flex-1 sm:flex-none"
        :look="isQuickBuyEnabled ? 'outlined-color' : 'filled'"
        @click="withAccessCheck({ callback: addToCart })"
      >
        {{
          adding || isCheckingIdentityVerification ? 'Loading' : 'Add to cart'
        }}
      </base-button>
      <base-button
        v-if="isQuickBuyEnabled"
        :disabled="
          adding || isAddButtonDisabled || isCheckingIdentityVerification
        "
        class="flex-1 sm:flex-none"
        @click="withAccessCheck({ callback: addToQuickBuy })"
      >
        Buy Now
      </base-button>
    </div>
  </div>
</template>
